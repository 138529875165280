import React from 'react'
import { Router } from '@reach/router'
import RAC from '../../../templates/RAC'
import DynamicNotFoundPage from '../../dynamicNotFound'

export default () => (
  <Router>
    <RAC path="/ja/islamic-finance/*" />
    <RAC path="/zh-cn/islamic-finance/*" />
    <RAC path="/zh-tw/islamic-finance/*" />
    <RAC path="/pl/islamic-finance/*" />
    <RAC path="/ru/islamic-finance/*" />
    <RAC path="/it/islamic-finance/*" />
    <RAC path="/de/islamic-finance/*" />
    <RAC path="/es/islamic-finance/*" />
    <RAC path="/fr/islamic-finance/*" />
    <RAC path="/ar/islamic-finance/*" />
    <RAC path="/id/islamic-finance/*" />
    <RAC path="/pt/islamic-finance/*" />
    <RAC path="/ko/islamic-finance/*" />
    <DynamicNotFoundPage default />
  </Router>
)
